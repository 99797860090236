import {Fade, Img} from '@chakra-ui/react'
import CloseIconImg from './assets/close-icon.png'
import OpenIconImg from './assets/open-icon.png'

export default function ToggleMenuButton({isOpen, onOpen, onClose}) {
  return (
    <button onClick={isOpen ? onClose : onOpen}>
      {isOpen ? (
        <Fade in={isOpen}>
          <Img src={CloseIconImg} w={`45px`} />
        </Fade>
      ) : (
        <Fade in={!isOpen}>
          <Img src={OpenIconImg} w={`45px`} />
        </Fade>
      )}
    </button>
  )
}
