import {Box, Heading, Image, Text, VStack} from '@chakra-ui/react'

import ContentWrapper from '../ContentWrapper'

import beach4 from '../../assets/b4.png'

const imageBackgroundStylePropsRewards = {
  src: `${beach4}`,
  objectFit: `cover`,
  objectPosition: `50% 50%`,
  h: `100%`,
  w: `100%`,
}

export default function MemeCompetition() {
  return (
    <VStack
      as="section"
      pos="relative"
      justifyContent="center"
      minH="22vh"
      id={`whitelist`}
    >
      <Box {...{pos: `absolute`, top: 0, bottom: 0, right: 0, left: 0}}>
        <Image {...imageBackgroundStylePropsRewards} />
      </Box>
      <ContentWrapper>
        <VStack>
          <Heading
            fontSize={`calc((3.8 - 1) * 1.2vh + 1rem)`}
            textShadow="0px 4px 0px #fff"
            textAlign={`center`}
          >
            <Text as="span" color="blackAlpha.900">
              Join The NFT Whitelist
            </Text>
          </Heading>
        </VStack>
      </ContentWrapper>
    </VStack>
  )
}
