const variants = {
  outline: {
    field: {
      bg: `#fafafa`,
      borderColor: `#ccc`,
      borderWidth: `1px`,
      borderStyle: `solid`,
      _focus: {
        bg: `whiteAlpha.800`,
        transition: `background .1s ease-in`,
        borderColor: `blackAlpha.900`,
        boxShadow: `0 0 0 1px`,
      },
    },
  },
}

const sizes = {
  md: {
    field: {
      borderRadius: `base`,
    },
  },
}

export default {
  sizes,
  variants,
}
