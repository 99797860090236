import WalletConnectProvider from '@walletconnect/web3-provider'

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    // options: {
    //   infuraId:
    //     process.env.REACT_APP_DEV_MODE === 'production'
    //       ? `${process.env.REACT_APP_INFURA_PROJECT_ID_PROD}`
    //       : `${process.env.REACT_APP_INFURA_PROJECT_ID_DEV}`, // required
    // },

    options: {
      rpc: {
        1: `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY_PROD}`,
        4: `https://eth-rinkeby.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_KEY_DEV}`,
      },
    },
  },
}
