import {Flex} from '@chakra-ui/react'

export default function Card({children, ...props}) {
  return (
    <Flex
      bg="white"
      borderWidth={`2px`}
      borderStyle={`solid`}
      borderColor={`#E8E8E8`}
      direction={`column`}
      boxShadow={{base: `0`, md: `6px 6px 0px #E8E8E8`}}
      p={8}
      {...props}
    >
      {children}
    </Flex>
  )
}
