/* eslint-disable eqeqeq */
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  HStack,
  Image,
  Img,
  Link,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import {useRef} from 'react'
import {Link as RLink, useResolvedPath} from 'react-router-dom'
import DiscordWhite from '../../../../assets/discord/light-hover.png'
import Discord from '../../../../assets/discord/light.png'
// import DarkMode from '../../../../assets/switch/dark-mode.png'
// import LightMode from '../../../../assets/switch/light-mode.png'
import TelegramHover from '../../../../assets/telegram/light-hover.png'
import Telegram from '../../../../assets/telegram/light.png'
import Logo from '../../../../assets/Thicc+Frens+Logo.png'
import TwitterHover from '../../../../assets/twitter/light-hover.png'
import Twitter from '../../../../assets/twitter/light.png'
import {socialUrls} from '../../utils'
// import MobileSwitchLight from './assets/switch-light.png'
import ToggleMenuButton from './components/ToggleMenuButton'
import {FrenHubMenuButton} from './components'

const headerStyleProps = {
  as: `header`,
  boxSizing: `border-box`,
  height: {base: `60px`, lg: `100px`},
  p: {base: `8px 16px`, md: `20px 60px`},
  pointerEvents: `auto`,
  pos: `relative`,
  w: `100%`,
  zIndex: 5,
}

const headerInnerStyleProps = {
  alignItems: `center`,
  display: `flex`,
  minH: {base: `45px`, md: `68px`},
  height: `inherit`,
  justifyContent: `space-between`,
  position: `relative`,
  width: `100%`,
  zIndex: 5,
}

const headerLogoStyleProps = {
  h: {base: `40px`, md: `60px`},
}

const headerNavStackStyleProps = {
  direction: {base: `column`, lg: `row`},
  p: {base: 10, lg: 0},
  h: `full`,
}

const LinkCustom = ({children, ...props}) => {
  const resolved = useResolvedPath(props.to)

  const isActive = (resolved) => {
    if (
      resolved.pathname === window.location.pathname &&
      resolved.hash === window.location.hash
    ) {
      return {
        bg: `black`,
        color: `white`,
        fontWeight: `bold`,
      }
    }
  }

  return (
    <Link
      as={props.as}
      {...isActive(resolved)}
      {...props}
      _hover={{
        bg: `black`,
        color: `white`,
        fontWeight: `bold`,
      }}
    >
      {children}
    </Link>
  )
}

const LinkStyled = ({children, ...props}) => {
  if (props.as !== `a`) {
    return (
      <LinkCustom fontSize={`20px`} px={2} py={1} {...props}>
        {children}
      </LinkCustom>
    )
  }

  return (
    <Link fontSize={`20px`} px={2} py={1} as={props.as} {...props}>
      {children}
    </Link>
  )
}

function NavItems({onOpen, isOpen, onClose}) {
  const [isLargerThan768] = useMediaQuery(`(min-width: 766px)`)
  const discordEffect = useRef()
  const twitterEffect = useRef()
  const telegramEffect = useRef()

  return (
    <Flex {...headerNavStackStyleProps}>
      {isLargerThan768 ? (
        <Flex gap={4}>
          <Stack
            direction={{base: `column`, lg: `row`}}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <LinkStyled as={RLink} to="/nft">
              NFT
            </LinkStyled>
            <LinkStyled as={RLink} to="/token">
              Token
            </LinkStyled>
          </Stack>
          <Stack
            direction={{base: `column`, lg: `row`}}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Link
              as={`a`}
              href={socialUrls.discordUrl}
              onMouseOver={() => (discordEffect.current.src = DiscordWhite)}
              onMouseOut={() => (discordEffect.current.src = Discord)}
            >
              <Img ref={discordEffect} src={Discord} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.telegramUrl}
              onMouseOut={() => (telegramEffect.current.src = Telegram)}
              onMouseOver={() => (telegramEffect.current.src = TelegramHover)}
            >
              <Img ref={telegramEffect} src={Telegram} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.twitterUrl}
              onMouseOut={() => (twitterEffect.current.src = Twitter)}
              onMouseOver={() => (twitterEffect.current.src = TwitterHover)}
            >
              <Img ref={twitterEffect} src={Twitter} w={`42px`} h={`38px`} />
            </Link>
            <FrenHubMenuButton />
          </Stack>
        </Flex>
      ) : (
        <>
          <Stack
            direction={{base: `column`, lg: `row`}}
            justifyContent="center"
            alignItems="center"
            spacing={10}
          >
            <LinkStyled as={RLink} to="/nft">
              NFT
            </LinkStyled>
            <LinkStyled as={RLink} to="/token">
              Token
            </LinkStyled>
          </Stack>
          <Stack
            isInline
            justifyContent="center"
            alignItems="center"
            spacing={4}
            mt={9}
          >
            <Link
              as="a"
              href={socialUrls.telegramUrl}
              onMouseOut={() => (telegramEffect.current.src = Telegram)}
              onMouseOver={() => (telegramEffect.current.src = TelegramHover)}
            >
              <Img ref={telegramEffect} src={Telegram} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.twitterUrl}
              onMouseOut={() => (twitterEffect.current.src = Twitter)}
              onMouseOver={() => (twitterEffect.current.src = TwitterHover)}
            >
              <Img ref={twitterEffect} src={Twitter} w={`42px`} h={`38px`} />
            </Link>
          </Stack>
          <Stack mt={6} alignItems={`center`} spacing={8}>
            <Link
              as={`a`}
              href={socialUrls.discordUrl}
              onMouseOver={() => (discordEffect.current.src = DiscordWhite)}
              onMouseOut={() => (discordEffect.current.src = Discord)}
            >
              <Flex alignItems={`center`} fontSize={`20px`}>
                <Img ref={discordEffect} src={Discord} w={`42px`} h={`38px`} />
                <Text>Join the Fam</Text>
              </Flex>
            </Link>

            <FrenHubMenuButton />

            <ToggleMenuButton
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
            />
          </Stack>
        </>
      )}
    </Flex>
  )
}

export default function Header() {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isLargerThan1025] = useMediaQuery(`(min-width: 1025px)`)

  return (
    <Stack {...headerStyleProps}>
      <HStack {...headerInnerStyleProps}>
        <Link as="a" href="/">
          <Image {...headerLogoStyleProps} src={Logo} />
        </Link>

        {isLargerThan1025 ? (
          <NavItems />
        ) : (
          <>
            <ToggleMenuButton onOpen={onOpen} isOpen={isOpen} />

            <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
              <DrawerContent height={`500px`} p="0">
                <DrawerBody>
                  <NavItems onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </HStack>
    </Stack>
  )
}
