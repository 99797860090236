import {Flex} from '@chakra-ui/react'

const contentWrapperStyleProps = {
  justifyContent: `center`,
  alignItems: `center`,
  m: `0 auto`,
  pos: `relative`,
  px: {base: `6vw`, md: `3vw`},
  width: `100%`,
  maxW: `1512px`,
}

export default function ContentWrapper({children, ...props}) {
  return (
    <Flex {...contentWrapperStyleProps} {...props}>
      {children}
    </Flex>
  )
}
