import {extendTheme} from '@chakra-ui/react'
import components from './components'

export const theme = extendTheme({
  fonts: {
    heading: `lo-res-15, lo-res-12, "proxima-nova", "Helvetica Neue", Helvetica , Arial, sans-serif`,
    body: `lo-res-12, "proxima-nova", "Helvetica Neue", Helvetica , Arial, sans-serif`,
  },
  components,
})

export default theme
