import {ChakraProvider} from '@chakra-ui/react'
import {MoralisProvider} from 'react-moralis'

import Theme from './chakra'
import AppRoutes from './router'

function App() {
  return (
    <ChakraProvider theme={Theme}>
      <MoralisProvider
        // serverUrl="https://cjzrs64vjqza.usemoralis.com:2053/server"
        serverUrl={
          process.env.REACT_APP_DEV_MODE === 'production'
            ? 'https://un1ctidzdjsg.usemoralis.com:2053/server'
            : 'https://iyzit7aynwss.usemoralis.com:2053/server'
        }
        appId={
          process.env.REACT_APP_DEV_MODE === 'production'
            ? `${process.env.REACT_APP_MORALIS_KEY_PROD}`
            : `${process.env.REACT_APP_MORALIS_KEY_DEV}`
        }
      >
        <AppRoutes />
      </MoralisProvider>
    </ChakraProvider>
  )
}

export default App
