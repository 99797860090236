import React, {lazy, Suspense} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ErrorPage from '../pages/ErrorPage'
import RootPage from '../pages/RootPage'

const HomePage = lazy(() => import(`../pages/HomePage`))
const FrenHubPage = lazy(() => import(`../pages/FrenHubPage`))
const TokenPage = lazy(() => import(`../pages/TokenPage`))
const ThankyouPage = lazy(() => import(`../pages/ThankyouPage`))
const NotFound = lazy(() => import(`../pages/NotFound`))

export default function AppRoutes() {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Router>
        {/* dont let non-critical pieces of UI crash the whole app */}
        <Suspense fallback={null}>
          <Routes>
            <Route element={<RootPage />} path="/">
              <Route element={<HomePage />} index />
              <Route element={<HomePage />} path="/nft" />
              <Route element={<FrenHubPage />} path="/fren-hub" />
              <Route element={<TokenPage />} path="/token" />
              <Route element={<ThankyouPage />} path="/thank-you" />
              <Route element={<NotFound />} path="*" />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </ErrorBoundary>
  )
}
