import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Img,
  Input,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import {useState} from 'react'
import ThiccStickers from '../../assets/thicc-stickers.png'
import ContentWrapper from '../ContentWrapper'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
    .join(`&`)
}

export default function ContactUs() {
  const toast = useToast()
  const [state, setState] = useState({
    name: ``,
    email: ``,
    message: ``,
  })


  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  }

  const handleSubmit = (event) => {
    fetch(`/`, {
      method: `POST`,
      headers: {'Content-Type': `application/x-www-form-urlencoded`},
      body: encode({'form-name': `contact`, ...state}),
    })
      .then(() => {
        toast({
          title: `Thank you`,
          description: `We get notified. We will contact you as soon as possible`,
          duration: 1000,
          isClosable: true,
          position: `bottom`,
          status: `success`,
        })
        setState({
          name: ``,
          email: ``,
          message: ``,
        })
      })
      .catch((error) => alert(error))

    event.preventDefault()
  }

  return (
    <Box w="100%">
      <ContentWrapper pb="3.3vmax">
        <Stack w={{base: `100%`, md: `75%`}} mx="-17px">
          <Box p={{base: `17px`}}>
            <Divider borderColor="black" />
          </Box>
          <Stack
            direction={{base: `column`, md: `row`}}
            spacing={{base: 20, md: 10}}
            py="3vw"
            w="auto"
            flexWrap="wrap"
          >
            <Stack alignItems="center" flex="1" justifyContent="center">
              <Heading
                textAlign={`center`}
                fontSize={{
                  base: `calc((2.8 - 1) * 1.2vh + 1rem)`,
                  sm: `calc((2.8 - 1) * 1.2vw + 1rem)`,
                }}
              >
                Telegram Sticker Pack
              </Heading>

              <Box w={{base: `100%`, md: `70%`}}>
                <Img src={ThiccStickers} />
              </Box>
              <Button
                size="lg"
                my="4"
                fontSize="2xl"
                as={`a`}
                href={`https://t.me/addstickers/ThiccInu`}
                target={`_blank`}
              >
                Add Stickers
              </Button>
            </Stack>

            <Stack
              alignItems="center"
              flex="1"
              maxW={{base: `100%`, md: `40%`}}
            >
              <Heading
                textAlign={`center`}
                fontSize={{
                  base: `calc((2.8 - 1) * 1.2vh + 1rem)`,
                  sm: `calc((2.8 - 1) * 1.2vw + 1rem)`,
                }}
              >
                Contact Us
              </Heading>

              <Box
                as="form"
                name="contact"
                method="post"
                w="100%"
                data-netlify="true"
                data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-contact" value="contact" />
                <FormControl>
                  <FormLabel>Name *</FormLabel>
                  <Input
                    onChange={handleChange}
                    value={state.name}
                    name="name"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email *</FormLabel>
                  <Input
                    onChange={handleChange}
                    value={state.email}
                    type="email"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Message *</FormLabel>
                  <Textarea
                    onChange={handleChange}
                    value={state.message}
                    name="message"
                  ></Textarea>
                </FormControl>

                <Button size="lg" my="4" fontSize="2xl" type="submit" w="100%">
                  Submit
                </Button>
              </Box>
            </Stack>
          </Stack>
          <Box p={{base: `17px`}}>
            <Divider borderColor="black" />
          </Box>
        </Stack>
      </ContentWrapper>
    </Box>
  )
}
