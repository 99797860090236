import {Heading} from '@chakra-ui/react'

export default function ReguleHeading({children, ...props}) {
  return (
    <Heading
      fontFamily={`regule5`}
      fontSize={{base: `45px`, lg: `50px`}}
      letterSpacing={`0.1em`}
      {...props}
    >
      {children}
    </Heading>
  )
}
