import {Box, Img, Link, Stack, Text, VStack} from '@chakra-ui/react'
import {useRef} from 'react'
import {Link as RLink, useResolvedPath} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import OpenSea from '../Header/assets/OpenSea Light.png'
import OpenSeaDark from '../Header/assets/OpenSea Dark.png'
import DiscordWhite from '../../../../assets/discord/light-hover.png'
import Discord from '../../../../assets/discord/light.png'
import InstagramHover from '../../../../assets/instagram/light-hover.png'
import Instagram from '../../../../assets/instagram/light.png'
import TelegramHover from '../../../../assets/telegram/light-hover.png'
import Telegram from '../../../../assets/telegram/light.png'
import TikTokHover from '../../../../assets/tiktok/light-hover.png'
import TikTok from '../../../../assets/tiktok/light.png'
import TwitterHover from '../../../../assets/twitter/light-hover.png'
import Twitter from '../../../../assets/twitter/light.png'
import {ContentWrapper} from '../../../../components'
import {socialUrls} from '../../utils'

const LinkCustom = ({children, ...props}) => {
  const resolved = useResolvedPath(props.to)

  const isActive = (resolved) => {
    if (
      resolved.pathname === window.location.pathname &&
      resolved.hash === window.location.hash
    ) {
      return {
        bg: `black`,
        color: `white`,
        fontWeight: `bold`,
      }
    }
  }

  return (
    <Link
      as={props.as}
      {...isActive(resolved)}
      {...props}
      _hover={{
        bg: `black`,
        color: `white`,
        fontWeight: `bold`,
      }}
    >
      {children}
    </Link>
  )
}

const LinkStyled = ({children, ...props}) => {
  if (props.as !== `a`) {
    return (
      <LinkCustom fontSize={`20px`} px={2} py={1} {...props}>
        {children}
      </LinkCustom>
    )
  }

  return (
    <Link fontSize={`20px`} px={2} py={1} as={props.as} {...props}>
      {children}
    </Link>
  )
}

export default function Footer() {
  const discordEffect = useRef()
  const instagramEffect = useRef()
  const twitterEffect = useRef()
  const tiktokEffect = useRef()
  const telegramEffect = useRef()
  const openSea = useRef()

  return (
    <ContentWrapper h="258px">
      <VStack spacing={5}>
        <Box>
          <Stack
            direction={`row`}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <LinkStyled
              as={`a`}
              href={socialUrls.opensea}
              onMouseOver={() => (openSea.current.src = OpenSeaDark)}
              onMouseOut={() => (openSea.current.src = OpenSea)}
              _hover={{bg: `black`}}
              p={`0.25`}
            >
              <Img ref={openSea} src={OpenSea} w={`42px`} h={`38px`} />
            </LinkStyled>
            <Link
              as={`a`}
              href={socialUrls.discordUrl}
              onMouseOver={() => (discordEffect.current.src = DiscordWhite)}
              onMouseOut={() => (discordEffect.current.src = Discord)}
            >
              <Img ref={discordEffect} src={Discord} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.telegramUrl}
              onMouseOut={() => (telegramEffect.current.src = Telegram)}
              onMouseOver={() => (telegramEffect.current.src = TelegramHover)}
            >
              <Img ref={telegramEffect} src={Telegram} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.twitterUrl}
              onMouseOut={() => (twitterEffect.current.src = Twitter)}
              onMouseOver={() => (twitterEffect.current.src = TwitterHover)}
            >
              <Img ref={twitterEffect} src={Twitter} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.tiktokUrl}
              onMouseOut={() => (tiktokEffect.current.src = TikTok)}
              onMouseOver={() => (tiktokEffect.current.src = TikTokHover)}
            >
              <Img ref={tiktokEffect} src={TikTok} w={`42px`} h={`38px`} />
            </Link>
            <Link
              as="a"
              href={socialUrls.instagramUrl}
              onMouseOut={() => (instagramEffect.current.src = Instagram)}
              onMouseOver={() => (instagramEffect.current.src = InstagramHover)}
            >
              <Img
                ref={instagramEffect}
                src={Instagram}
                w={`38px`}
                h={`38px`}
              />
            </Link>
          </Stack>
        </Box>
        <Stack
          direction={`row`}
          justifyContent="center"
          alignItems="center"
          spacing={5}
          flexWrap={`wrap`}
        >
          <LinkStyled as={RLink} to="/nft">
            NFT
          </LinkStyled>
          <LinkStyled as={RLink} to="/token">
            Token
          </LinkStyled>
          <LinkStyled as={HashLink} to="/nft/#nft-staking">
            Staking
          </LinkStyled>
          <LinkStyled as={`a`} target="_blank" href={socialUrls.certikAudit}>
            Certik Audit
          </LinkStyled>
          <LinkStyled target="_blank" as={`a`} href={socialUrls.whitepaperUrl}>
            Whitepaper
          </LinkStyled>
          <LinkStyled target="_blank" as={`a`} href={socialUrls.opensea}>
            Opensea
          </LinkStyled>
          <LinkStyled target="_blank" as={`a`} href={socialUrls.raritySniper}>
            Rarity Sniper
          </LinkStyled>
          <LinkStyled target="_blank" as={`a`} href={socialUrls.termOfUseUrl}>
            Terms of Use / Legal
          </LinkStyled>
          {/*
        <LinkStyled as={HashLink} to="/nft/#whitelist">
          Whitelist
        </LinkStyled> */}
        </Stack>
        <Stack>
          <Text color="rgba(0,0,0, 50%)">
            Ⓒ Thicc Frens {new Date().getFullYear()}
          </Text>
        </Stack>
      </VStack>
    </ContentWrapper>
  )
}
