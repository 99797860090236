import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './fonts/lo-res12narrow/LoRes12OT-NarReg.ttf'
import './fonts/lo-res12regular/LoRes12OT-Reg.ttf'
import './fonts/lo-res15boldboldalternat/LoRes15OT-Bold.ttf'
import './fonts/lo-res15boldboldalternat/LoRes15OT-BoldAltOakland.ttf'
import './fonts/Regule5/Regule5-Bold.otf'
import './fonts/Regule5/Regule5.otf'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'


function getLibrary(provider) {
  return new Web3Provider(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
    <App />
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById(`root`)
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
