import {Box, Flex, Stack} from '@chakra-ui/react'
import {useReactCountdown} from 'use-react-countdown'

const showNewCountdown = `May 31, 2022 18:00:00 GMT-04:00`

export default function Countdown() {
  const {days, hours, minutes, seconds} = useReactCountdown(
    new Date(showNewCountdown).getTime()
  )

  return (
    <Stack
      isInline
      fontSize={{base: `12px`, lg: `16px`}}
      fontWeight={{base: `bold`}}
      justifyContent={`center`}
      alignItems={`center`}
    >
      <Flex direction={{base: `column`}} textAlign={`center`}>
        <span>{`${days} `}</span>
        <Box as="small">DAYS</Box>
      </Flex>
      <Flex>
        <span>:</span>
      </Flex>
      <Flex direction={{base: `column`}} textAlign={`center`}>
        <span className="hours">{`${hours} `}</span>
        <Box as="small">HOURS</Box>
      </Flex>
      <Flex>
        <span>:</span>
      </Flex>
      <Flex direction={{base: `column`}} textAlign={`center`}>
        <span className="minutes">{`${minutes} `}</span>
        <Box as="small">MINUTES</Box>
      </Flex>
      <Flex>
        <span>:</span>
      </Flex>
      <Flex direction={{base: `column`}} textAlign={`center`}>
        <span className="seconds">{`${seconds} `}</span>
        <Box as="small">SECONDS</Box>
      </Flex>
    </Stack>
  )
}
