import React from 'react'
import {Box, Button, Flex, Heading, Text} from '@chakra-ui/react'

export default function ErrorPage({
  children,
  emoji = `🥺`,
  heading = `Oh no!`,
  refresh,
  subheading = `Sorry for the technical problems. We have been notified of the issue and should resolve it soon.`,
  resetErrorBoundary,
}) {
  return (
    <Flex
      align="center"
      bg="gray.800"
      direction="column"
      height="100vh"
      justify="center"
      p={4}
      textAlign="center"
    >
      <Box
        bg="white"
        borderRadius="lg"
        boxShadow="md"
        maxWidth={320}
        px={4}
        py={12}
      >
        <Box aria-label="Emoji" fontSize="4xl" role="img">
          {emoji}
        </Box>
        <Heading as="h1" size="lg">
          {heading}
        </Heading>
        <Text fontSize="sm" mt={2}>
          {subheading}
        </Text>

        <Button
          mt={4}
          onClick={() => {
            resetErrorBoundary()
            // window.location.reload(true)
            window.location.href('/')
          }}
          size="sm"
        >
          Try again
        </Button>

        {children}
      </Box>
    </Flex>
  )
}
