import {
  VStack,
  Flex,
  Image,
  Heading,
  Box,
  Text,
  OrderedList,
  ListItem,
  Stack,
} from '@chakra-ui/react'

import dogRocket from '../../assets/6.1.png'
import dogStyle from '../../assets/8.1.png'
import tokenLauch from '../../assets/m1.png'
import nftLauch from '../../assets/m2.png'
import Black_bg from '../../assets/black_background.png'

import ContentWrapper from '../ContentWrapper'

const containerValues = {
  pos: `relative`,
  as: `section`,
  color: `white`,
}

const listItemValues = {
  color: `white`,
  pl: `30px`,
}
const listPlanValues = {
  flexDir: {base: `column`, md: `row`},
  height: {base: `100%`, md: `460px`},
  overflow: `hidden`,
}
const listContainerValues = {
  w: {base: `100vw`, md: `auto`},
  p: `17px`,
}
const listItemsValues = {
  lineHeight: `2em`,
  fontSize: {base: `1.1rem`, sm: `0.875rem`, md: `1.25rem`},
}

const informationSectionValues = {
  p: 4,
  w: {base: `100%`, md: `calc(50% - 4%)`},
}
const containerTextValues = {
  lineHeight: `100px`,
  maxW: `450px`,
  w: {base: `100%`, md: `50%`},
  justify: `center`,
  flexDir: `column`,
}
const textValues = {
  fontWeight: `400`,
  lineHeight: `2em`,
}
const imagesListValues = {
  width: {base: `100%`, md: `auto`},
}

const flexInformationValues = {
  direction: {base: `column`, sm: `row`},
  flexWrap: `wrap`,
}
const flexInformationValues2 = {
  flexDir: {base: `column-reverse`, sm: `row`},
}

const headignValues = {
  fontSize: `2.5rem`,
}

const imageBackgroundStylePropsThiccNfts = {
  src: `${Black_bg}`,
  objectFit: `cover`,
  objectPosition: `50% 50%`,
  h: `100%`,
  w: `100%`,
}

export default function ThiccMarketing() {
  return (
    <VStack {...containerValues}>
      <Box {...{pos: `absolute`, top: 0, bottom: 0, right: 0, left: 0}}>
        <Image {...imageBackgroundStylePropsThiccNfts} />
      </Box>

      <ContentWrapper pb="6.6vmax">
        <VStack {...{w: {base: `100%`, md: `75%`}}}>
          <VStack>
            <Flex {...flexInformationValues}>
              <Box {...informationSectionValues}>
                <Image src={dogRocket} />
              </Box>

              <Stack
                {...{direction: `column`, flex: 1, justifyContent: `center`}}
              >
                <Heading {...headignValues}>Thicc Marketing</Heading>

                <Text {...textValues}>
                  Thicc Frens was created by a strong network of marketing
                  professionals and influencers with a marketing first mindset.
                  In addition to influencers, thicc community members are
                  incentivized to spread the word through meme submissions.
                </Text>
              </Stack>
            </Flex>
          </VStack>

          <VStack>
            <Flex {...listPlanValues}>
              <Box {...listContainerValues}>
                <Image {...imagesListValues} src={nftLauch} />
                <OrderedList as="ol" {...listItemValues}>
                  <ListItem {...listItemsValues}>100+ Influencers</ListItem>
                  <ListItem {...listItemsValues}>Telegram ads</ListItem>
                  <ListItem {...listItemsValues}>Influencer giveaways</ListItem>
                  <ListItem {...listItemsValues}>Instagram ads</ListItem>
                  <ListItem {...listItemsValues}>Tiktok ads</ListItem>
                  <ListItem {...listItemsValues}>Twitter ads</ListItem>
                </OrderedList>
              </Box>
              <Box {...listContainerValues} alignSelf={`center`}>
                <Image {...imagesListValues} src={tokenLauch} />
                <OrderedList as="ol" {...listItemValues}>
                  <ListItem {...listItemsValues}>200+ Influencers</ListItem>
                  <ListItem {...listItemsValues}>Telegram ads</ListItem>
                  <ListItem {...listItemsValues}>
                    Times Square Billboard
                  </ListItem>
                  <ListItem {...listItemsValues}>
                    Influencers partnerships
                  </ListItem>
                  <ListItem {...listItemsValues}>
                    Giveaways and competitions
                  </ListItem>
                </OrderedList>
              </Box>
            </Flex>
          </VStack>

          <Flex {...flexInformationValues2}>
            <Flex {...containerTextValues}>
              <Heading {...headignValues}>Thicc Security</Heading>
              <Text {...textValues}>
                Thicc's token contract has been audited and verified to be safe
                and secure. View security audit.
              </Text>
            </Flex>
            <Box {...informationSectionValues}>
              <Image src={dogStyle} />
            </Box>
          </Flex>
        </VStack>
      </ContentWrapper>
    </VStack>
  )
}
