import {Box, Button, Flex, Heading, Stack, Text} from '@chakra-ui/react'
import {socialUrls} from '../../pages/RootPage/utils'

export default function JoinSquad() {
  return (
    <Stack
      direction={{base: `column`, md: `row`}}
      justifyContent={`space-between`}
      alignItems={`center`}
      w="100%"
      py={{base: 4, lg: 10}}
    >
      <Flex
        direction={`column`}
        justifyContent={{base: `center`, md: `flex-start`}}
        gap={`10px`}
      >
        <Heading
          textAlign={{base: `center`, md: `left`}}
          as="h2"
          fontSize={{base: `28px`, lg: `calc((2.8 - 1) * 1.2vh + 1rem)`}}
        >
          5,555 thicc dogs that'll twerk & earn you $THICC tokens
        </Heading>

        <Text
          textAlign={{base: `center`, md: `left`}}
          fontSize={{base: `20px`, lg: `calc((1.3 - 1) * 1.2vh + 1rem)`}}
          color={`#424C4D`}
        >
          Public mint sold out, buy on <a href={socialUrls.opensea}>OpenSea</a>
        </Text>
      </Flex>

      <Flex direction={{base: `column`}}>
        <Box py={{base: 5, md: 3}}>
          <Button as={`a`} href={socialUrls.opensea}>
            Buy a Thicc Fren
          </Button>
        </Box>
      </Flex>
    </Stack>
  )
}
