import {Box, Button, Heading, Stack, Text} from '@chakra-ui/react'
import {Card, ContentWrapper} from '..'

export default function BuyThiccToken({bgColor = '#F3F3F3'}) {
  return (
    <Box as="section" bg={bgColor}>
      <ContentWrapper py="6" px={{base: `0`, md: `3vw`}}>
        <Stack
          direction={{base: `column`, lg: `row`}}
          justifyContent="space-between"
        >
          <Card p={{base: 3, lg: 8}}>
            <Heading fontSize={{base: `xl`, lg: `3xl`}}>
              ETH Contract Address
            </Heading>
            <Text fontSize={{base: `sm`, lg: `xl`}}>
              0x2F05577606f1Ae37DC3a553f28916e22dE49A2F9
            </Text>
            <Text
              as="a"
              href="https://etherscan.io/address/0x2f05577606f1ae37dc3a553f28916e22de49a2f9"
              target="_blank"
              lineHeight={`35px`}
              textDecoration="underline"
            >
              View on Etherscan
            </Text>
            <Button
              as="a"
              target="_blank"
              href="https://app.uniswap.org/#/swap?outputCurrency=0x2F05577606f1Ae37DC3a553f28916e22dE49A2F9&chain=mainnet"
              w="80%"
              fontSize={{base: `lg`, lg: `2xl`}}
            >
              Buy $THICC on Uniswap
            </Button>
          </Card>
        </Stack>
      </ContentWrapper>
    </Box>
  )
}
