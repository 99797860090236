const baseStyle = {
  borderRadius: `none`,
}

const variants = {
  solid: {
    fontFamily: `lo-res-15`,
    bg: `#ff3e41`,
    py: 6,
    px: 10,
    border: `2px solid #484848`,
    boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    boxSizing: `border-box`,
    color: `white`,
    fontSize: `2xl`,
    _active: {
      bg: `#ff3e41`,
      boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    },
    _hover: {
      bg: `#ff3e41`,
      boxShadow: `inset -2px 0px 0px #FFFFFF, inset 0px -2px 0px #ffffff, inset 2px 0px 0px #808080, inset 0px 2px 0px #808080`,
    },
    _focus: {
      boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    },
  },
  frens: {
    fontFamily: `lo-res-15`,
    bg: `#ffffff`,
    py: 6,
    px: 10,
    border: `2px solid #484848`,
    boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    boxSizing: `border-box`,
    color: `black`,
    fontSize: `2xl`,
    _active: {
      boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    },
    _hover: {
      boxShadow: `inset -2px 0px 0px #FFFFFF, inset 0px -2px 0px #ffffff, inset 2px 0px 0px #808080, inset 0px 2px 0px #808080`,
    },
    _focus: {
      boxShadow: `inset -2px 0px 0px #808080, inset 0px -2px 0px #808080, inset 2px 0px 0px #FFFFFF, inset 0px 2px 0px #FFFFFF`,
    },
  },
}

export default {
  baseStyle,
  variants,
}
