import {
  Button,
  Center,
  Divider,
  Flex,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react'
import {useState, useEffect, useRef} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'

import Web3Modal from 'web3modal'
import {ethers} from 'ethers'

import {providerOptions} from './providerOptions'

import Avatar from './assets/thumb_avatar.png'

export default function FrenHubMenuButton() {
  const toast = useToast()
  const navigate = useNavigate()

  const [provider, setProvider] = useState()
  const [library, setLibrary] = useState()
  const [account, setAccount] = useState()
  const [chainId, setChainId] = useState()

  const [walletConnected, setWalletConnected] = useState(false)

  const web3Modal = useRef()

  useEffect(() => {
    web3Modal.current = new Web3Modal({
      // network:
      //   process.env.REACT_APP_DEV_MODE === 'production' ? 'mainnet' : 'rinkeby', // optional
      cacheProvider: false, // optional
      providerOptions, // required
    })
  }, [])

  // There is a copy of this in FrenHubPage
  const connectWallet = async () => {
    try {
      const provider = await web3Modal.current.connect()
      const library = new ethers.providers.Web3Provider(provider)
      const accounts = await library.listAccounts()
      const network = await library.getNetwork()
      setProvider(provider)
      setLibrary(library)

      const connectedAccount = accounts[0]
      if (accounts) setAccount(connectedAccount)
      setChainId(network.chainId)

      console.log('account: ', accounts[0])

      console.log('network.chainId: ', network.chainId)
      if (
        process.env.REACT_APP_DEV_MODE === 'production' &&
        network.chainId !== 1
      ) {
        toast({
          title: 'Error',
          description: 'Not connected to Ethereum Mainnet',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })

        return
      } else {
        // toast({
        //   title: 'Account connected',
        //   description: 'You may now proceed to Fren Hub!',
        //   status: 'success',
        //   duration: 5000,
        //   isClosable: true,
        // })

        toast({
          title: 'Information',
          description: 'Please wait while the data is loading',
          status: 'info',
          duration: 9000,
          isClosable: true,
        })

        window.thiccFrensObj = {}
        window.thiccFrensObj.provider = provider
        window.thiccFrensObj.account = connectedAccount
        navigate('/fren-hub', {replace: true})
      }

      setWalletConnected(true)
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const disconnect = async () => {
    await web3Modal.current.clearCachedProvider()
    refreshState()
    window.location.replace('/')
  }

  const refreshState = () => {
    setAccount()
    setChainId()
  }

  // useEffect(() => {
  //   if (web3Modal.current.cachedProvider) {
  //     connectWallet()
  //   }
  // }, [])

  // async function requestAccount() {
  //   // With only metamask
  //   // const [account] = await window.ethereum.request({
  //   //   method: 'eth_requestAccounts',
  //   // })
  //   // console.log('account: ', account)
  // }

  function handleDisconectWalletConnection() {
    setWalletConnected(false)
  }

  if (!walletConnected && window.location.pathname !== '/fren-hub') {
    // if (window.thiccFrensObj === undefined) {
    console.log('window.thiccFrensObj: ', window.thiccFrensObj)
    return (
      <Button variant="frens" onClick={connectWallet}>
        {/* Connect Wallet */}
        Fren Hub
      </Button>
      // <WalletConnectModal />
    )
  }

  return (
    <Flex gap={4} justifyContent="center" alignItems="center">
      <Center height="40px">
        <Divider orientation="vertical" d={{base: `none`, lg: `block`}} />
      </Center>
      {/* <Link
        as={RouterLink}
        to="/fren-hub"
        bg="black"
        fontWeight="bold"
        color="white"
        size="sm"
        py="2"
        px="4"
        fontFamily="lo-res-15"
        height={`40px`}
      >
        Fren Hub
      </Link> */}
      <Menu>
        <MenuButton>
          <Img width="50px" height="50px" src={Avatar} />
        </MenuButton>
        <MenuList>
          {/* <MenuItem onClick={handleDisconectWalletConnection}>
            Disconnect Wallet
          </MenuItem> */}
          <MenuItem onClick={disconnect}>Disconnect Wallet</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
