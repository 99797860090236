import {Box} from '@chakra-ui/react'
import {Outlet} from 'react-router-dom'
import {Footer, Header} from './components'

const rootPageStyleProps = {
  minH: `100vh`,
}

const mainContentStyleProps = {
  minH: `66vh`,
  w: `100%`,
  zIndex: 1,
}

export default function RootPage() {
  return (
    <Box as="section" {...rootPageStyleProps}>
      <Box pos="fixed" w={`100%`} top={0} bg="white" zIndex={2}>
        <Header />
      </Box>
      <Box
        as="main"
        {...mainContentStyleProps}
        pt={{
          base: `50px`,
          lg: `100px`,
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}
