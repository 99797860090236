export default {
  certikAudit: `https://www.certik.com/projects/thicc-frens`,
  discordUrl: `https://discord.com/invite/thicc-frens`,
  getWhitelistedUrl: `https://www.premint.xyz/thicc-frens/`,
  instagramUrl: `https://www.instagram.com/thiccfrens/`,
  opensea: `https://opensea.io/collection/thicc-frens-genesis`,
  raritySniper: `https://raritysniper.com/nft-drops-calendar`,
  telegramUrl: `https://t.me/ThiccFrens`,
  termOfUseUrl: `https://docs.google.com/document/d/1UPG9LI3XjQ/_lrFkrmX/_k86MThoNsCmGSOxJIodJjqQY/edit?usp=sharing`,
  tiktokUrl: `https://www.tiktok.com/@thiccfrens`,
  twitterUrl: `https://twitter.com/ThiccFrens`,
  whitepaperUrl: `https://docs.google.com/presentation/d/1meZgs2dXB3HSfWYMBX3Xs1WmAj3SnQtvgOxOx-RkKNc/edit?usp=sharing`,
  ethLiquidity: `https://app.unicrypt.network/amm/uni-v2/pair/0x151F7dC81E63B7B25a981C6809e7Bba34A963E7B`,
  bscLiquidity: `https://app.unicrypt.network/amm/pancake-v2/pair/0x53B75158BDA9058E3Bd39761562363ed4a5A412e`,
}
