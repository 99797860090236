import {Button} from '@chakra-ui/react'
import dayjs from 'dayjs'

import Countdown from '../Countdown/Countdown'

const showNewCountdown = `May 31, 2022 18:00:00 GMT-04:00`

export default function PreMintButton() {
  const isAfterNewCountdown = dayjs().isAfter(showNewCountdown)

  return (
    <>
      {isAfterNewCountdown ? (
        <Button as={`a`} href={`/mint`}>
          Mint Thicc Frens
        </Button>
      ) : (
        <Countdown />
      )}
    </>
  )
}
